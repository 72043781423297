@if (playerId && playerData) {
  <header class="top-bar flex-row justify-center" aria-label="header" i18n-aria-label>
    <mat-icon
      class="arrow-back cursor-pointer"
      (click)="navigateToEventsPage()"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleRadius]="50"
      >arrow_back</mat-icon
    >
    <h2 class="page-label justify-center" i18n>Profile</h2>
    @if (isOwner) {
      <mat-icon
        class="va-middle mr-10 cursor-pointer arror-right"
        svgIcon="{{ !editable ? 'edit' : 'done' }}"
        (click)="!editable ? onEditClick() : onDoneClick()"
        *hbFeatureFlag="true"
      ></mat-icon>
      <mat-icon class="logout-button" alt="logout" i18n-alt svgIcon="logout" (click)="logout()"></mat-icon>
    }
  </header>

  <form [formGroup]="profileForm">
    <div class="profile-form">
      <div class="user-profile flex-col align-center">
        <div mat-card-avatar class="user-profile-image">
          @if (playerData.photoUrl) {
            <img
              class="image"
              loading="lazy"
              [src]="playerData.photoUrl"
              alt="player photo"
              aria-label="A photo of user"
              i18n-alt
              i18n-aria-label
            />
          } @else {
            <div class="initials">
              {{ initial }}
            </div>
          }
        </div>
        @if (!editable) {
          <p class="mat-option-text user-name mb-5">{{ playerData.firstName }} {{ playerData.lastName }}</p>
        } @else {
          <mat-form-field appearance="fill">
            <input
              matInput
              class="mat-option-text user-name"
              [value]="playerData.firstName + ' ' + playerData.lastName"
              formControlName="name"
              autofocus
            />
          </mat-form-field>
        }
        @if (playerData.nickName && playerData.nickName !== 'N/A') {
          <p class="user-id mat-option-text mb-5 info-text">&#64;{{ playerData.nickName }}</p>
        }
        @if (playerData.city) {
          <div class="flex-row mb-5">
            <mat-icon svgIcon="location-blue" class="location-icon h-13"></mat-icon>
            <p class="location mat-option-text mb-5">{{ playerData.city }}</p>
          </div>
        }
        <hb-socials [playerData]="playerData!" *hbFeatureFlag="true"></hb-socials>
      </div>

      @if (!isOwner) {
        <div class="bottom-navbar fill-area" *hbFeatureFlag="true">
          <div class="flex-row flex-wrap space-evenly align-center gap-16">
            <button
              class="follow flex-1 outline-button"
              mat-raised-button
              (click)="followClick()"
              aria-label="Click here to follow or unfollow the user"
              i18n-aria-label
            >
              <mat-icon class="va-middle">remove_red_eye</mat-icon>
              <span class="va-middle" i18n>{{ isFollowing ? 'Follow' : 'Unfollow' }}</span>
            </button>
            <button
              class="add-friend flex-1 fill-button"
              mat-raised-button
              (click)="followFriend()"
              aria-label="Click here to friend or unfriend the user"
              i18n-aria-label
            >
              <mat-icon class="va-middle">{{ isFriend ? 'add' : 'remove' }}</mat-icon>
              <span class="va-middle" i18n>{{ isFriend ? 'Add Friend' : 'Unfriend' }}</span>
            </button>
          </div>
        </div>
      }

      <div (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
        <mat-tab-group mat-align-tabs="start" (focusChange)="focusChange($event.index)" [selectedIndex]="selectedTab">
          <mat-tab i18n-label label="Info" *hbFeatureFlag="true">
            @if(!loading && playerData) {
              <hb-profile-info
              [editable]="editable"
              [playerData]="playerData"
              [positions]="positions"
              [isOwner]="isOwner"
            ></hb-profile-info>
            }
          </mat-tab>
          <mat-tab i18n-label label="Events">
            <ng-template mat-tab-label i18n
              >Events
              <div class="badge">
                <p class="badge-text">{{ totalEventCount }}</p>
              </div>
            </ng-template>
            <ng-container *hbFeatureFlag="false">
              @if(!loading) {
                @for (group of uniqueStartDates; track group) {
                  <div class="event-card-container cursor-pointer">
                    <p class="date-group">
                      {{ group | weekDay | date: 'EEEE, d MMM' | titlecase }}
                    </p>
                    @for (event of events; track event.id) {
                      @if (event.startDate === group) {
                        <hb-event-card [event]="event" (onEventClick)="redirectToEventDetails($event)"> </hb-event-card>
                      }
                    }
                  </div>
                }
              }  
            </ng-container>
            <ng-container *hbFeatureFlag="true">
              @if(!loading) {
                @for (event of events; track event.id) {
                  <hb-event-card [event]="event" (onEventClick)="redirectToEventDetails($event)"> </hb-event-card>
                }
              }
            </ng-container>
          </mat-tab>
          <mat-tab i18n-label label="Following" *hbFeatureFlag="true">
            @if (followingUsers.length) {
              @for (player of followingUsers; track player.id) {
                <hb-player-card [player]="player"></hb-player-card>
              }
            }
          </mat-tab>
          <mat-tab i18n-label label="Followers" *hbFeatureFlag="true">
            @if (followers.length) {
              @for (player of followers; track player.id) {
                <hb-player-card [player]="player"></hb-player-card>
              }
            }
          </mat-tab>
          <mat-tab i18n-label label="Friends" *hbFeatureFlag="true">
            @if (friends.length) {
              @for (player of friends; track player.id) {
                <hb-player-card [player]="player"></hb-player-card>
              }
            }
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </form>
  @if (loading) {
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  } 
}
