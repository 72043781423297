import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'hb-socials',
  templateUrl: './socials.component.html',
  standalone: true,
  imports: [MatIconModule, MatTooltipModule, NgIf, NgFor]
})
export class SocialsComponent {
  @Input() playerData!: any;

  socialMediaPlatforms = ['facebookUrl', 'instagramUrl', 'twitterUrl'];
  socialMediaIcons = ['facebook', 'instagram', 'twitter'];
}
