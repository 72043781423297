import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

import { Hobby, User } from '../../../services/hobbyts.service';
import { PositionModalComponent } from '../../../modals/position-modal/position-modal.component';
import { ProfileService } from 'src/app/core/profile/profile.service';
import { LinkComponent } from './links/link.component';
import { DialogNames, LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { getSnackBarConfig } from 'src/app/utils/material.utils';
import { IPosition } from 'src/app/shared/model/position.interface';
import { HobbyComponent } from 'src/app/shared/hobby';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hb-profile-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    NgFor,
    MatIconModule,
    NgClass,
    LinkComponent,
    HobbyComponent
  ]
})
export class InfoComponent implements OnInit {
  @Input() public positions: IPosition[] = [];
  @Input() public playerData!: User;
  @Input() public editable!: boolean;
  @Input() public isOwner: boolean;

  public profileForm!: FormGroup;

  public visibleHobbies: Hobby[] = [];
  public allHobbies: Hobby[] = [];
  public showAllHobbies = false;

  public bio = '';
  public facebookLink: string | undefined | null;
  public instagramLink: string | undefined | null;
  public twitterUrl: string | undefined | null;
  public phoneNumber: string | undefined | null;
  public maxHobbiesToShow = 3;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private lazyDialog: LazyDialogService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.generateForm(this.playerData);
    this.allHobbies = this.profileService.getSelectedHobbyItems() || [];
    this.visibleHobbies = [...this.allHobbies.slice(0, this.maxHobbiesToShow)];
  }

  public openPositionDialog(): void {
    if (this.isOwner && this.editable) {
      this.openPositionModalDialog();
    }
  }

  public openSnackBar(message: string, buttonTxt: string): void {
    this.snackBar.open(message, buttonTxt, getSnackBarConfig());
  }

  public inputChange(event: Event): void {
    this.bio = (event.target as HTMLInputElement).value;

    const existing = this.profileService.profileObj$.getValue();

    this.profileService.profileObj$.next({
      ...existing,
      bio: (event.target as HTMLInputElement).value
    });
  }

  public socialInputChange(event: Event, type: string): void {
    const value = this.profileService.profileObj$.getValue();

    if (type === 'facebook') {
      this.facebookLink = (event.target as HTMLInputElement).value;
      this.profileService.profileObj$.next({
        ...value,
        facebookUrl: this.facebookLink
      });
    }
    if (type === 'instagramUrl') {
      this.instagramLink = (event.target as HTMLInputElement).value;
      this.profileService.profileObj$.next({
        ...value,
        instagramUrl: this.instagramLink
      });
    }

    if (type === 'twitterUrl') {
      this.twitterUrl = (event.target as HTMLInputElement).value;
      this.profileService.profileObj$.next({
        ...value,
        twitterUrl: this.twitterUrl
      });
    }

    if (type === 'phoneNumber') {
      this.phoneNumber = (event.target as HTMLInputElement).value;
      this.profileService.profileObj$.next({
        ...value,
        phoneNumber: this.phoneNumber
      });
    }
  }

  public toggleShowAllHobbies(): void {
    this.showAllHobbies = !this.showAllHobbies;
    if (this.showAllHobbies) {
      this.visibleHobbies = [...this.allHobbies];
    } else {
      this.visibleHobbies = [...this.allHobbies.slice(0, this.maxHobbiesToShow)];
    }
  }

  private generateForm(playerData: User): void {
    this.allHobbies = playerData?.hobbies || [];
    this.visibleHobbies = [...this.allHobbies.slice(0, this.maxHobbiesToShow)];

    this.profileForm = this.formBuilder.group({
      bio: [playerData?.bio, Validators.required],
      facebookUrl: [playerData?.facebookUrl],
      instagramUrl: [playerData?.instagramUrl],
      twitterUrl: [playerData?.twitterUrl],
      phoneNumber: [playerData?.phoneNumber]
    });

    this.facebookLink = playerData?.facebookUrl;
    this.instagramLink = playerData?.instagramUrl;
    this.twitterUrl = playerData?.twitterUrl;
    this.phoneNumber = playerData?.phoneNumber;
  }

  private openPositionModalDialog(): void {
    let dialogRef;
    const config = {
      height: '100%',
      maxWidth: '100%',
      data: this.positions
    };
    this.lazyDialog
      .openLazyLoadedDialog<PositionModalComponent>(DialogNames.POSITION, config)
      .then((ref) => {
        dialogRef = ref;

        dialogRef.afterClosed().subscribe((result: IPosition[]) => {
          if (result) {
            this.positions = result;
            const positions = result.map((p: { title: string }) => p.title);
            this.profileService.updatePosition(positions).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
              () => {
                this.openSnackBar('Positions updated successfully.', 'Close');
              },
              (error: Error) => {
                this.openSnackBar(error.message, 'Close');
              }
            );
          }
        });
      })
      .catch((catchError: Error) => {
        console.error(catchError);
      });
  }
}
