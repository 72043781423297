<div class="flex-row gap-10" *ngIf="playerData">
  <ng-container *ngFor="let platform of socialMediaPlatforms; let i = index">
    <a *ngIf="playerData[platform]" [href]="playerData[platform]" target="_blank">
      <mat-icon [svgIcon]="socialMediaIcons[i]" class="h-16 w-16" [matTooltip]="playerData[platform]"
        [matTooltipPosition]="'below'" #tooltip="matTooltip"></mat-icon>
    </a>
  </ng-container>
  <a>
    <mat-icon svgIcon="call" class="h-16 w-16" [matTooltip]="playerData.phoneNumber!" [matTooltipPosition]="'below'"
      #tooltip="matTooltip"></mat-icon>
  </a>
</div>