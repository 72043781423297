<form [formGroup]="profileForm">
  <div class="bio-title info-text" *ngIf="editable" i18n>Social Links</div>
  <div class="links flex-col space-between gap-10" *ngIf="editable">
    <mat-form-field appearance="fill" class="border-bottom">
      <mat-label class="social-title">Facebook Url</mat-label>
      <input matInput formControlName="facebookUrl" (input)="socialInputChange($event, 'facebook')" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="border-bottom">
      <mat-label class="social-title">Instagram Url</mat-label>
      <input matInput formControlName="instagramUrl" (input)="socialInputChange($event, 'instagramUrl')" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="border-bottom">
      <mat-label class="social-title">Twitter Url</mat-label>
      <input matInput formControlName="twitterUrl" (input)="socialInputChange($event, 'twitterUrl')" />
    </mat-form-field>
    <mat-form-field appearance="fill" class="border-bottom">
      <mat-label class="social-title">Phone number</mat-label>
      <input matInput formControlName="phoneNumber" (input)="socialInputChange($event, 'phoneNumber')" />
    </mat-form-field>
  </div>

  <div class="bio-title info-text" i18n>Bio</div>
  <div class="bio-text" *ngIf="!editable; else bioInput">
    {{ playerData.bio }}
  </div>
  <ng-template #bioInput>
    <mat-form-field appearance="fill">
      <input matInput class="mat-option-text" [value]="playerData.bio" formControlName="bio"
        (input)="inputChange($event)" />
    </mat-form-field>
  </ng-template>

  <div class="custom-hr"></div>
  <div class="bio-title info-text" i18n>Hobby</div>

  <div class="friend-chips">
    <ng-container *ngIf="!editable; else chipInput">
      <mat-chip-grid #chipList>
        <mat-chip-row class="chip-row" *ngFor="let hobby of visibleHobbies">
          {{ hobby.title }}
        </mat-chip-row>
        <input class="hide-input" #chipInputRef matChipInput [matChipInputFor]="chipList" />
      </mat-chip-grid>
    </ng-container>
    <ng-template #chipInput>
      <hb-hobby [selectedHobbies]="allHobbies"></hb-hobby>
    </ng-template>
  </div>
  <div class="hobby-more" (click)="toggleShowAllHobbies()" *ngIf="allHobbies.length > maxHobbiesToShow && !editable">
    {{ showAllHobbies ? 'less...' : 'more...' }}
  </div>

  <div class="custom-hr"></div>
  <div class="bio-title info-text" (click)="openPositionDialog()" i18n>Positions</div>
  <div class="position flex-row flex-wrap start flex-start gap-10 align-center" (click)="openPositionDialog()">
    <div *ngFor="let position of positions">
      <div [ngClass]="['position-' + (position.isFirst ? 'first' : 'default'), 'position-' + position?.color]">
        <span class="position-text">{{ position?.title }}</span>
      </div>
    </div>
  </div>

  <div class="custom-hr"></div>

  <ng-container *ngIf="playerData">
    <hb-info-links [editable]="editable" [playerData]="playerData"></hb-info-links>
  </ng-container>
</form>