<mat-form-field appearance="fill" class="hobby-mat-form-field">
  @if (!selectedHobbies.length) {
    <mat-label class="mat-label" i18n>Select Hobbies</mat-label>
  }
  <mat-chip-grid #chipList cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
    @for(hobby of selectedHobbies; track hobby.id) {
      <mat-chip-row cdkDrag [removable]="removable" (removed)="removeHobby(hobby)">
        {{ hobby.title }}
        @if (removable) {
          <mat-icon matChipRemove>cancel</mat-icon>
        }
      </mat-chip-row>
    }
    <input [formControl]="hobbiesCtrl" [matAutocomplete]="autoGroup" #chipInputRef matChipInput
      [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addHobby($event)" />
    <mat-autocomplete #autoGroup="matAutocomplete" (optionSelected)="selected($event)">
      @for (group of hobbyGroups; track group.id) {
        @if (isAnyHobbyOptionForGroup(group.id, autocompleteHobbyOptions | async)){
          <mat-optgroup [label]="group.title">
            @for (hobby of autocompleteHobbyOptions | async; track hobby.id) {
              @if (hobby.groupId === group.id) {
                <mat-option [value]="hobby">
                  {{ hobby.title }}
                </mat-option>
              }
            }
          </mat-optgroup>
        }
      }
    </mat-autocomplete>
  </mat-chip-grid>
</mat-form-field>