import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { ProfileService } from 'src/app/core/profile/profile.service';
import { GroupModalComponent } from 'src/app/modals/group-modal';
import { Group, Hobby } from 'src/app/services/hobbyts.service';
import { DialogNames, LazyDialogService } from 'src/app/services/lazy-dialog.service';
import { getMatDialogConfig } from 'src/app/utils/material.utils';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'hb-hobby',
  templateUrl: './hobby.component.html',
  standalone: true,
  styleUrls: ['./hobby.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    DragDropModule,
    CommonModule,
    MatIconModule
  ]
})
export class HobbyComponent implements OnInit {
  @Input() public selectedHobbies: Array<Hobby> = [];
  @ViewChild('chipInputRef') public chipInputRef: ElementRef<HTMLInputElement> | undefined;

  public hobbyGroups: Group[] = [];
  public hobbies: Array<Hobby> = [];
  public selectable = true;
  public removable = true;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public autocompleteHobbyOptions: Observable<Array<Hobby>>;
  public hobbiesCtrl = new FormControl("");

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private lazyDialog: LazyDialogService,
    private featureFlagService: FeatureFlagService,
    private destroyRef: DestroyRef
  ) { }

  public ngOnInit(): void {
    this.profileService.getHobbies().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: any) => {
      this.hobbyGroups = data.getAllHobbies.hobbyGroups;
      this.hobbies = data.getAllHobbies.hobbies;
      if (this.featureFlagService.getFeature()) {
        const value = this.profileService.profileObj$.getValue();
        const selectedHobby = this.hobbies.find((hobby: Hobby) => hobby.title === "football")

        if (selectedHobby) {
          const { __typename, ...hobby } = selectedHobby;
          this.selectedHobbies.push(hobby);
          this.hobbiesCtrl.disable();
          this.removable = false;
          this.selectable = false;
          this.profileService.profileObj$.next({
            ...value,
            hobbies: [hobby]
          });
        }
      }
    });
    this.autocompleteHobbyOptions = this.hobbiesCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterHobbiesByTitle(value || ''))
    );
  }

  public async addHobby(event: MatChipInputEvent): Promise<void> {
    const value = (event.value || '').trim();
    const config = getMatDialogConfig(this.dialog, GroupModalComponent, this.hobbyGroups);
    await this.lazyDialog.openLazyLoadedDialog<GroupModalComponent>(DialogNames.HOBBY_GROUP, config).then((ref) => {
      ref.afterClosed().subscribe((data) => {
        if (data && value) {
          this.handleCreateHobby({ groupId: data.id, title: value });
        }
        event.chipInput.clear();
      });
    });
  }

  public removeHobby(hobbyToRemove: Hobby): void {
    const value = this.profileService.profileObj$.getValue();
    this.selectedHobbies = this.selectedHobbies.filter((obj) => !this.isEqual(obj, hobbyToRemove));
    this.profileService.profileObj$.next({
      ...value,
      hobbies: this.selectedHobbies
    });
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedHobbies = [...this.selectedHobbies, event.option.value];

    const value = this.profileService.profileObj$.getValue();

    if (this.chipInputRef?.nativeElement) {
      this.chipInputRef.nativeElement.value = '';
    }
    this.hobbiesCtrl.setValue('');

    this.profileService.profileObj$.next({
      ...value,
      hobbies: this.selectedHobbies
    });
  }

  public drop(event: CdkDragDrop<string[]>): void {
    this.selectedHobbies = [...this.selectedHobbies];
    moveItemInArray(this.selectedHobbies, event.previousIndex, event.currentIndex);
    const value = this.profileService.profileObj$.getValue();
    this.profileService.profileObj$.next({
      ...value,
      hobbies: this.selectedHobbies
    });
  }

  public isAnyHobbyOptionForGroup(groupId: string, hobbies: Array<Hobby> | null): boolean {
    if (hobbies) {
      return hobbies.some((option) => option.groupId === groupId);
    }
    return true;
  }

  private filterHobbiesByTitle(value: string): Array<Hobby> {
    if (value && typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.hobbies.filter((hobby) => hobby.title && hobby.title.toLowerCase().includes(filterValue));
    }
    return this.hobbies;
  }

  private isEqual(obj1: Hobby, obj2: Hobby): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  private handleCreateHobby(newHobby: Hobby): void {
    const existing = this.profileService.profileObj$.getValue();
    this.profileService.createHobby(newHobby).pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data: any) => {
      this.selectedHobbies = [...this.selectedHobbies, data.data.createHobby];
      this.profileService.profileObj$.next({
        ...existing,
        hobbies: this.selectedHobbies
      });
    });
  }
}
