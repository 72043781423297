import { ChangeDetectionStrategy, Component, DestroyRef, HostBinding, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { Event, EventTypeWithCount, User, UserProfileType } from 'src/app/services/hobbyts.service';
import { ProfileService } from 'src/app/core/profile/profile.service';
import { PlayerCardComponent } from '../events/player-card/player-card.component';
import { SocialsComponent } from '../profile/socials/socials.component';
import { NotificationDetailComponent } from '../notifications/norification-detail/notification-detail.component';
import { EventCardComponent } from '../events/event-card/event-card.component';
import { InfoComponent } from './info/info.component';
import { getSnackBarConfig } from 'src/app/utils/material.utils';
import { generateInitials, generatePositions } from 'src/app/utils/profile.utils';
import { IPosition } from 'src/app/shared/model/position.interface';
import { AuthService } from 'src/app/entry/auth/auth.service';
import { FeatureFlagDirective } from 'src/app/shared/feature-flag.directive';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { WeekDayPipe } from 'src/app/pipe/weekday.pipe';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadProgressService } from 'src/app/services/load-progress.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'hb-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
    MatButtonModule,
    MatTabsModule,
    InfoComponent,
    EventCardComponent,
    NotificationDetailComponent,
    PlayerCardComponent,
    SocialsComponent,
    FeatureFlagDirective,
    WeekDayPipe,
    CommonModule,
    MatRippleModule,
    MatProgressSpinnerModule
  ]
})
export class ProfileComponent implements OnInit {
  @HostBinding('class.background-host-page') public backgroundHostPageClass = true;
  public playerId: string;
  public playerData: User | undefined;
  public positions: IPosition[] = [];
  public isOwner: boolean;
  public isFollowing = true;
  public isFriend = true;
  public events: Event[] = [];
  public followingUsers: User[] = [];
  public followers: User[] = [];
  public friends: User[] = [];
  public totalEventCount = 0;
  public initial = '';
  public editable = false;
  public selectedTab = 0;
  public selectedCity: string | undefined;
  public uniqueStartDates: string[];
  public loading = false;

  public profileForm = this.formBuilder.group({
    name: ['', Validators.required]
  });

  private swipeCoord: any;
  private swipeTime = 0;

  constructor(
    private router: Router,
    private location: Location,
    private activeRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    protected sanitizer: DomSanitizer,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private destroyRef: DestroyRef,
    private loadingService: LoadProgressService
  ) {
    this.loading = this.loadingService.loadingState();
   }

  public ngOnInit(): void {
    this.activeRoute.paramMap.subscribe((params) => {
      this.playerId = params.get('id') ?? '';
      this.initSubscriptions();
      this.selectedTab = 0;
    });

    if (this.featureFlagService.getFeature()) {
      this.profileService.getEventsByUserId(this.playerId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ getEventsByUserId }: any) => {
        this.setEventsData(getEventsByUserId);
      });
    }
  }

  public navigateToEventsPage(): void {
    this.location.back();
  }

  public followClick(): void {
    if (this.isFollowing) {
      this.profileService.followClick(this.playerId, this.isFollowing).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ followUser }: any) => {
        const res = followUser;
        this.openSnackBar(res, 'close');
      });
    } else {
      this.profileService.unFollowClick(this.playerId, this.isFollowing).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ unFollowUser }: any) => {
        const res = unFollowUser;
        this.openSnackBar(res, 'close');
      });
    }
    this.isFollowing = !this.isFollowing;
  }

  public followFriend(): void {
    if (this.isFriend) {
      this.profileService.followFriend(this.playerId, this.isFriend).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ addFriend }: any) => {
        const res = addFriend;
        this.openSnackBar(res, 'close');
      });
    } else {
      this.profileService.unFollowFriend(this.playerId, this.isFriend).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ removeFriend }: any) => {
        const res = removeFriend;
        this.openSnackBar(res, 'close');
      });
    }
    this.isFriend = !this.isFriend;
  }

  public focusChange(selectedTab: number): void {
    if (selectedTab === 1) {
      this.profileService.getEventsByUserId(this.playerId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ getEventsByUserId }: any) => {
        this.setEventsData(getEventsByUserId);
      });
    }

    if (selectedTab === 2) {
      this.profileService.getFollowingByUserId(this.playerId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ getFollowingByUserId }: any) => {
        this.followingUsers = getFollowingByUserId;
        this.generateUsersData(this.followingUsers);
      });
    }

    if (selectedTab === 3) {
      this.profileService.getFollowersByUserId(this.playerId).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ getFollowersByUserId }: any) => {
        this.followers = getFollowersByUserId;
        this.generateUsersData(this.followers);
      });
    }

    if (selectedTab === 4) {
      this.profileService.getFriendsEnrolledInEvent().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(({ getFriendsWithMe }: any) => {
        this.friends = getFriendsWithMe;
        this.generateUsersData(this.friends);
      });
    }
  }

  public redirectToEventDetails(eventId: string): void {
    if(this.featureFlagService.getFeature()) {
      this.router.navigate(['edit-event', eventId]);
    } else {
      this.router.navigate(['event-details', eventId]);
    }
  }

  public onEditClick(): void {
    this.editable = !this.editable;
    this.profileForm.get('name')?.setValue(`${this.playerData?.firstName} ${this.playerData?.lastName}`);
  }

  public onDoneClick(): void {
    const profileObject = {
      ...(this.profileService.profileObj$.getValue() ?? {})
    };

    let objToUpdate: Partial<User> = {};

    if (profileObject) {
      objToUpdate = {
        ...profileObject
      };
    }

    if (this.profileForm.valid) {
      const name = this.profileForm.value.name;
      if (name) {
        objToUpdate.firstName = name.split(' ')[0];
        objToUpdate.lastName = name.split(' ')[1];
      }

      this.editable = !this.editable;

      this.profileService.updateUser(objToUpdate).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
        next: ({ updateUser }: any) => {
          if (updateUser) {
            const { firstName, lastName, facebookUrl, instagramUrl, twitterUrl, phoneNumber, city, bio, hobbies } =
              updateUser;
            if (this.playerData) {
              this.playerData = {
                ...this.playerData,
                firstName,
                lastName,
                facebookUrl,
                instagramUrl,
                twitterUrl,
                phoneNumber,
                city,
                addedBy: '',
                bio: bio || this.playerData.bio,
                hobbies
              } as any;
            }
            this.initial = generateInitials(firstName, lastName);

            if (objToUpdate.hobbies) {
              this.profileService.updateSelectedHobbyItems(hobbies);
            }

            if ('hobbies' in objToUpdate || 'newHobbies' in objToUpdate) {
              this.snackBar.open($localize`Hobbies are changed`, 'Close', getSnackBarConfig());
            }
          }
        },
        error: () => {
          this.snackBar.open($localize`Error in profile change`, 'Close', getSnackBarConfig());
        }
      });
    }
  }

  public swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';

        if (swipe === 'next') {
          const isFirst = this.selectedTab === 0;
          if (this.selectedTab <= 3) {
            this.selectedTab = isFirst ? 1 : this.selectedTab + 1;
          }
        } else if (swipe === 'previous') {
          if (this.selectedTab >= 1) {
            this.selectedTab = this.selectedTab - 1;
          }
        }
        this.focusChange(this.selectedTab);
      }
    }
  }

  public trackByFn(index: number, item: any): any {
    return item.id ?? item;
  }

  public logout(): void {
    this.authService.logout();
  }

  private generateUsersData(users: any): void {
    if (users) {
      for (const user of users) {
        user.playerId = user.id;
        user.positions = user.positions ? generatePositions(user) : [];
        user.initial = generateInitials(user?.firstName, user?.lastName);
      }
    }
  }

  private openSnackBar(message: string, buttonTxt: string): void {
    this.snackBar.open(message, buttonTxt, getSnackBarConfig());
  }

  private initSubscriptions(): void {
    if (this.playerId) {
      this.profileService
        .getUserProfile(this.playerId)
        .pipe(
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(({ getUserProfile }: any) => {
          const userProfile = getUserProfile as UserProfileType;
          if (userProfile) {
            // ToDo: check why totalEventCount here is not same number as in eventsById?
            // this.totalEventCount = userProfile.totalEvent ?? 0;
            this.isOwner = userProfile.isOwner ?? false;

            if (userProfile.userProfileData) {
              this.playerData = {...userProfile.userProfileData};
              this.selectedCity = this.playerData.city ?? '';
              if (userProfile.userProfileData.photoUrl) {
                this.playerData.photoUrl = this.sanitizer.sanitize(
                  SecurityContext.RESOURCE_URL,
                  this.sanitizer.bypassSecurityTrustResourceUrl(userProfile.userProfileData.photoUrl)
                );
              }
              this.initial = generateInitials(this.playerData.firstName, this.playerData.lastName);
              this.positions = generatePositions(this.playerData);
            }

            this.checkIfPlayerisFollowerOrFriend(userProfile);
          }
        });
    }
  }

  private checkIfPlayerisFollowerOrFriend(userProfile: UserProfileType): void {
    if (userProfile.followers) {
      const foundFollowerIndex = userProfile.followers.findIndex((f: any) => f.id === this.playerId);
      if (foundFollowerIndex !== -1) {
        this.isFollowing = false;
      }
    }
    if (userProfile.friends) {
      const foundFriendIndex = userProfile.friends.findIndex((f: any) => f.id === this.playerId);
      if (foundFriendIndex !== -1) {
        this.isFriend = false;
      }
    }
  }

  private setEventsData(getEventsByUserId: EventTypeWithCount): void {
    if (getEventsByUserId) {
      this.events = [...getEventsByUserId.result.map(data => ({ ...data}))];
      this.totalEventCount = getEventsByUserId.totalCount;
      this.uniqueStartDates = Array.from(new Set(this.events.map((event) => event.startDate)));
    }
  }
}
